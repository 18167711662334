// 3-rd party packages
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  Navigate,
  useNavigate
} from 'react-router-dom';
// import { GoogleOAuthProvider } from '@react-oauth/google';

import bkgd_img from './imgs/default_background.png';

// Images
import placeHolder_logo from './imgs/KidsTales_logo.png';
import profile_placeholder from './imgs/generic_profile.png';

// Shared Page Components
import Header from './header/Header';
import Footer from './footer/Footer';

// Main Pages
import StoryGenStartPage from './story/StoryGenStartPage';
import StoryGenSummaryPage from './story/StoryGenSummaryPage';
import StoryGenBookPage from './story/StoryGenBookPage';
import StoryBookPage from './story/book/Book';
import StoryPage from './story/book/StoryPage';
import BookShelfPage from './story/BookShelfPage';

// Menu Pages
import LoginPage from './login/LoginPage';
import ProfileInformationPage from './account/ProfileInformationPage';
import CreateProfilePage from './account/CreateProfilePage';
import AccountPage from './account/AccountPage';
import PinModule from './account/pin/PinModule';
import PricingPage from './pricing/PricingPage';
import CheckoutPage from './pricing/CheckoutPage';
import AIProfileCreator from './account/AIProfileCreator';

import ProtectedRoute from './utils/ProtectedRoute';  // Import the ProtectedRoute component

// Context
import { useStory } from './context/StoryContext';
import { useShelf } from './context/BookShelfContext';
import { useUser } from './context/UserContext';

// Utils
import API from './utils/api_axios';

// Styles
import './App.css';


const demo_id = "1";

const dismissX = <svg fill="#000000" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M256,0C114.84,0,0,114.842,0,256s114.84,256,256,256s256-114.842,256-256S397.16,0,256,0z M256,462.452 c-113.837,0-206.452-92.614-206.452-206.452S142.163,49.548,256,49.548S462.452,142.163,462.452,256S369.837,462.452,256,462.452z "></path> </g> </g> <g> <g> <polygon points="355.269,191.767 320.233,156.731 256,220.964 191.767,156.731 156.731,191.767 220.964,256 156.731,320.233 191.767,355.269 256,291.036 320.233,355.269 355.269,320.233 291.036,256 "></polygon> </g> </g> </g></svg>;
const magicalBookSvg = <svg xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" viewBox="0 0 297.001 297.001"><path d="M287.034,60.873l-20.819-0.001V39.321c0-4.934-3.61-9.126-8.49-9.856c-0.852-0.128-21.134-3.074-45.557,1.37 c-27.227,4.954-48.941,17.171-63.668,35.64c-14.728-18.469-36.442-30.686-63.668-35.64c-24.424-4.443-44.706-1.498-45.557-1.37 c-4.88,0.731-8.49,4.923-8.49,9.856v21.551H9.966C4.463,60.872,0,65.335,0,70.839v187.805c0,3.227,1.562,6.254,4.193,8.124 s6.004,2.35,9.051,1.288c0.748-0.259,75.431-25.747,131.12-0.345c2.628,1.199,5.645,1.199,8.273,0 c55.533-25.33,130.376,0.088,131.12,0.345c1.068,0.372,2.174,0.555,3.276,0.555c2.043,0,4.065-0.628,5.775-1.842 c2.631-1.87,4.193-4.897,4.193-8.124V70.84C297,65.336,292.538,60.873,287.034,60.873z M19.933,245.309V80.805h10.852v132.726 c0,2.896,1.267,5.646,3.458,7.539c2.191,1.893,5.105,2.742,7.969,2.319c0.55-0.08,43.846-6.024,75.478,15.679 C78.725,232.405,39.727,240.112,19.933,245.309z M138.534,230.08c-13.932-12.588-32.079-21.1-53.702-25.034 c-10.406-1.894-20.06-2.446-27.78-2.446c-2.292,0-4.414,0.049-6.333,0.126V48.473h-0.001c19.155-0.864,65.752,1.184,87.816,38.587 V230.08z M158.466,87.061c21.985-37.243,68.655-39.384,87.816-38.563v154.228c-8.383-0.338-20.62-0.136-34.114,2.32 c-21.623,3.934-39.77,12.445-53.702,25.034V87.061z M179.277,239.074c31.636-21.716,74.955-15.766,75.495-15.686 c2.871,0.431,5.783-0.413,7.981-2.305c2.198-1.894,3.462-4.65,3.462-7.552V80.806h10.852v164.503 C257.267,240.11,218.253,232.4,179.277,239.074z" fill="#000000"/><path d="M70,55 L80,75 L70,70 L60,75 L70,55 M200,70 L210,90 L200,85 L190,90 L200,70 M230,130 L240,150 L230,145 L220,150 L230,130 M100,170 L110,190 L100,185 L90,190 L100,170 M40,150 L50,170 L40,165 L30,170 L40,150" fill="#000000"/></svg>;

function App() {
  // Context Imports
  const { userId,
          setUserId,
          loggedIn, 
          setLoggedIn, 
          userProfile, 
          setUserProfile,
          userToken,
          setUserToken,
          activeProfileId, 
          setActiveProfileId, 
          profiles, 
          setProfiles, 
          credentialId,
          profilesPageActive,
          setProfilesPageActive,
          demoMode,
          setDemoMode
        } = useStory();

  const { landingShelfOn, 
          setLandingShelfOn,
          backgroundUrl } = useShelf();
  
  const { accountType,
          setAccountType,
          credits,
          setCredits } = useUser();

  // Page State
  // const [inputValue, setInputValue] = useState(0);
  const [alreadyFetchedProfiles, setAlreadyFetchedProfiles] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState(null);

  const location = useLocation(); // Get the current location
  const navigate = useNavigate();


  // var demoMode = false;

  // ============================================ Page useEffect ============================================
  useEffect(() => {
    // Shrink the logo on load
    // const kidsTalesLogo = document.getElementById('nav-container');
    // kidsTalesLogo.classList.remove('small');

    console.log('useEffect loggedIn:', loggedIn);
    console.log('useEffect userProfile:', userProfile);

    const loggedInStorage = localStorage.getItem('loggedIn');
    console.log('useEffect loggedInStorage:', loggedInStorage);
    if (loggedInStorage !== null) {
      setLoggedIn(loggedInStorage);
    }

    const userTokenStorage = localStorage.getItem('userToken');
    console.log('useEffect userTokenStorage:', userTokenStorage);
    if (userTokenStorage !== null) {
      setUserToken(userTokenStorage);
      setUserId(userTokenStorage);
    }

    const backgroundImageUrl = localStorage.getItem('backgroundImageUrl');
    SetBackgroundImage(backgroundImageUrl);

    // const userProfileStorage = localStorage.getItem('userProfile');
    // console.log('useEffect userProfileStorage:', userProfileStorage);
    // if (userProfileStorage !== null) {
    //   setUserProfile(JSON.parse(userProfileStorage));
    // }

    // 5.11.24 - Disabled because it was causing errors. registration worker seems to work for now
    // Load the service worker
    // if ('serviceWorker' in navigator) {
    //     navigator.serviceWorker.register('/service-worker.js', { type: 'module' }).then(registration => {
    //         console.log('ServiceWorker registration successful with scope: ', registration.scope);
    //     }).catch(err => {
    //         console.log('ServiceWorker registration failed: ', err);
    //     });

    //     // Listen for messages from the service worker
    //     navigator.serviceWorker.addEventListener('message', event => {
    //         if (event.data.type === 'OFFLINE') {
    //             // Display an offline notification or popup
    //             alert('You are currently browsing offline!');
    //         }
    //     });
    // }
  }, []);
  // ============================================ Page useEffect ============================================


  useEffect(() => {
    SetBackgroundImage(backgroundUrl);
  }, [backgroundUrl]);

  function SetBackgroundImage(imageUrl) {
    const coverContainer = document.getElementById('App-container');

    if (imageUrl) {
      const image = new Image();
      image.src = imageUrl;
  
      image.onload = () => {
          coverContainer.style.background = `url(${imageUrl}) no-repeat 50% 50% / cover`;
      };
  
      image.onerror = () => {
          const defaultImageUrl = bkgd_img;
          coverContainer.style.background = `url(${defaultImageUrl}) no-repeat 50% 50% / cover`;
      };
    } else {
      const defaultImageUrl = bkgd_img;
      coverContainer.style.background = `url(${defaultImageUrl}) no-repeat 50% 50% / cover`;
    }
  }

  // ============================================ RouteStyling ============================================
  useEffect(() => {
    const navContainer = document.querySelector('.nav-container');
    const profilesContainer = document.querySelector('.profiles-container');

    // Default state (home page styles)
    setLandingShelfOn(location.pathname === '/');

    // Apply styles based on the current route
    switch (location.pathname) {
      case '/':
      case '/create-profile-ai':
      case '/story-gen-start':
        navContainer.classList.remove('small', 'transparent');
        if (profilesContainer) profilesContainer.style.display = 'flex';
        break;
      case '/login':
      case '/story-gen-summary':
        navContainer.classList.remove('small', 'transparent');
        if (profilesContainer) profilesContainer.style.display = 'none';
        break;
      case '/pricing':
      case '/checkout':
        navContainer.classList.add('small');
        if (profilesContainer) profilesContainer.style.display = 'none';
        break;
      case '/book-shelf':
      case '/profile-information':
      case '/account':
        navContainer.classList.add('small');
        if (profilesContainer) profilesContainer.style.display = 'flex';
        break;
      default:
        // For any undefined route
        navContainer.classList.add('small');
        if (profilesContainer) profilesContainer.style.display = 'none';
    }
  }, [location.pathname]);
  // ============================================ RouteStyling ============================================

  // ============================================ Fetch user's profiles useEffect ============================================
  useEffect(() => {
    var profiles_list = [];

    // console.log('userProfile useEffect loggedIn:', loggedIn);
    // console.log('userProfile useEffect userProfile:', userProfile);
    // if (userProfile === null || userProfile === undefined) {
    if (!loggedIn || !userId || !userToken) {
      return;
    }

    if (loggedIn && userId) {
      // Fetch user credits
      const fetchUserCredits = async () => {
          try {
            const response = await API.post(`/api/user/get-credits`, { user_id: userId });
            console.log('User Credits updated:', response.data.creditsData);
            setCredits(response.data.creditsData);
          } catch (error) {
            console.error('Error fetching user credits:', error);
          }
        };

      fetchUserCredits();
    }

    if (loggedIn && userId && accountType === null) {
      const fetchAccountType = async () => {
        try {
          const accountType = await API.get(`/api/user/get-account-type`, {userId});
          console.log('Account Type:', accountType.data.accountType);
          setAccountType(accountType.data.accountType.toLowerCase());
        } catch (error) {
          console.error('Error fetching account type:', error);
        }
      };

      fetchAccountType();
    }

    if (loggedIn && !alreadyFetchedProfiles) {
      // axios.get('/api/files/list-profiles') // Fetch the list of profiles
      API.get('/api/files/get-user-profiles', { userId }) // Fetch the list of profiles
          .then((response) => {
              console.log("get profiles response: ", response);
              if (response.data.profiles) {
                  response.data.profiles.forEach((profile) => {
                    //get the data from the row, and the profile_data from the data
                    // const profile_data = profile.profile_data;
                    console.log("profile: ", profile);
                    profiles_list.push(profile);
                  });
                  if (profiles_list) {
                    profiles_list.forEach((profile) => {
                      console.log(profile);
                    });
                  }
                  
                  setProfiles(profiles_list);
                  setAlreadyFetchedProfiles(true);
                  // showProfiles();
              }
          })
          .catch((error) => {
              console.error('Error fetching profiles:', error);
              // if error unauthorized, set loggedIn to false
              if (error.response && error.response.status === 401) {
                Cookies.set('loggedIn', 'false');
                Cookies.set('userToken', 'null');
                setLoggedIn(false);
                setUserId(null);
                setUserToken(null);
              }
          });
    }

    console.log('profiles:', profiles);
  }, [loggedIn, userToken]);
  // ============================================ Fetch Profiles useEffect ============================================


  // ============================================ PWA Installer ============================================
  const [showPwaInstall, setShowPwaInstall] = useState(false);
  const [showAppleInstall, setShowAppleInstall] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [dismissTimeout, setDismissTimeout] = useState(null);
  localStorage.setItem('installPromptDismissed', 'false');
  localStorage.setItem('appInstalled', 'false');
  var appInstalled = localStorage.getItem('appInstalled');
  var installPromptDismissed = JSON.parse(localStorage.getItem('installPromptDismissed'));

  // check if the device is in standalone mode
  function isInStandaloneMode() {
//   const isInStandaloneMode = () => {
    console.log("navigator: ", navigator);
    console.log("navigator.standalone: ", navigator.standalone);
    console.log("navigator && navigator.standalone: ", navigator &&
      navigator.standalone);
    return (
      "standalone" in navigator &&
      navigator.standalone
    );
  };
  
  useEffect(() => {
    // Check if the user has previously dismissed the install prompt
    appInstalled = localStorage.getItem('appInstalled');
    installPromptDismissed = JSON.parse(localStorage.getItem('installPromptDismissed'));

    if (isInStandaloneMode === 'true') {
      console.log('Running in installed standalone mode.');
      return; // Exit early if they have dismissed the prompt before
    }

    if (installPromptDismissed === true) {
      console.log('User has previously dismissed the install prompt. Not showing it again.');
      return; // Exit early if they have dismissed the prompt before
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      // The rest of your existing code
      e.preventDefault();
      setDeferredPrompt(e);
      setShowPwaInstall(true);
    });

    // Check for Apple PWA install
    const shouldShowIosInstallModal = showIosInstallModal();
    console.log('userAgent testing shouldShowIosInstallModal:', shouldShowIosInstallModal);
    if (shouldShowIosInstallModal && !showPwaInstall.current) {
      setShowAppleInstall(true);
    }

    // window.addEventListener('beforeinstallprompt', (e) => {
    //   const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

    //   console.log("process.env.PUBLIC_URL: ", process.env.PUBLIC_URL);
    //   console.log("window.location.href: ", window.location.href);
    //   console.log("publicUrl: ", publicUrl);

    //   console.log("window.navigator.userAgent.toLowerCase(): ", window.navigator.userAgent.toLowerCase());

    //   // Prevent Chrome 67 and earlier from automatically showing the prompt
    //   e.preventDefault();
    //   // Stash the event so it can be triggered later
    //   setDeferredPrompt(e);
    //   // Show the install button
    //   setShowPwaInstall(true);
    // });
  }, []);

  function showInstallPrompt() {
//   const showInstallPrompt = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
          localStorage.setItem('appInstalled', 'true');
        } else {
          console.log('User dismissed the install prompt');
          // Store the dismissal in localStorage
          localStorage.setItem('installPromptDismissed', 'true');
        }
        setDeferredPrompt(null);
        setShowPwaInstall(false);
      });
    }
  };

  function dismissInstall() {
//   const dismissInstall = () => {
    setShowPwaInstall(false);
    setShowAppleInstall(false);
    localStorage.setItem('installPromptDismissed', 'true');
    installPromptDismissed = localStorage.getItem('installPromptDismissed');
  };



  // iOS PWA Installer
  function showIosInstallModal() {
//   const showIosInstallModal = () => {
    // detect if the device is on iOS
    const isIos = () => {
      // const userAgent = Platform.OS === 'ios';
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      console.log('userAgent:', userAgent);
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
      } else if (/Macintosh/.test(userAgent) && !window.MSStream) {
        return true;
      }
      return false;
    };
    
    // // check if the device is in standalone mode
    // const isInStandaloneMode = () => {
    //   return (
    //     "standalone" in navigator &&
    //     navigator.standalone
    //   );
    // };

    // const shouldShowModalResponse = isIos();
    
    // // show the modal only once
    // const localStorageKeyValue = localStorage.getItem(localStorageKey);
    // const iosInstallModalShown = localStorageKeyValue
    //   ? JSON.parse(localStorageKeyValue)
    //   : false;
    console.log('userAgent isIos:', isIos());
    console.log('userAgent isInStandaloneMode:', isInStandaloneMode());
    console.log('userAgent installPromptDismissed:', installPromptDismissed);
    const shouldShowModalResponse = isIos() && !isInStandaloneMode() && !installPromptDismissed;
    // if (shouldShowModalResponse) {
    //   localStorage.setItem(localStorageKey, "true");
    // }
    return shouldShowModalResponse;
  }

  // Dismiss the PWA install prompt after 30 seconds
  useEffect(() => {
    if (showPwaInstall) {
      const timeout = setTimeout(() => {
        dismissInstall();
      }, 30000); // 30 seconds

      setDismissTimeout(timeout);
    } else {
      clearTimeout(dismissTimeout);
    }
  }, [showPwaInstall]);

  // Dismiss the Apple install prompt after 30 seconds
  useEffect(() => {
    if (showAppleInstall) {
      const timeout = setTimeout(() => {
        dismissInstall();
      }, 30000); // 30 seconds

      setDismissTimeout(timeout);
    } else {
      clearTimeout(dismissTimeout);
    }
  }, [showAppleInstall]);
  // ============================================ PWA Installer ============================================


  // ============================================ Demo UI ============================================
  function showDemoUI() {
//   const showDemoUI = () => {
    const demoContainer = document.getElementById('demo-profiles-container');
    demoContainer.style.display = 'flex';
    demoContainer.style.height = 'fit-content';

    const demoInstructions = document.getElementById('start-demo-instructions');
    demoInstructions.style.display = 'flex';
    // demoContainer.style.visibility = 'visible';
  };
  // ============================================ Demo UI ============================================


  // ============================================ Page Functions ============================================
  // useEffect(() => {
  function showStartUI() {
//   const showStartUI = () => {
    setLandingShelfOn(true);
    setProfilesPageActive(false);

    const kidsTalesLogo = document.getElementById('nav-container');
    kidsTalesLogo.classList.remove('small');
    kidsTalesLogo.classList.remove('transparent');

    setActiveProfileId(null);

    console.log('credentialId:', credentialId);
    if (!loggedIn || demoMode) {
      const demoContainer = document.getElementById('demo-profiles-container');
      if (demoContainer) {
        demoContainer.style.display = 'flex';
      }

      setDemoMode(true);
      showDemoUI();
    } else {
      const demoContainer = document.getElementById('demo-profiles-container');
      if (demoContainer) {
        demoContainer.style.display = 'none';
      }

      const profilesContainer = document.getElementById('profiles-container');
      if (profilesContainer) {
        profilesContainer.style.display = 'flex';
      }

      setDemoMode(false);
    }
  }

  function SetActiveProfile(id) {
//   const SetActiveProfile = (id) => {
    if (loggedIn) {
      //USER MODE
      const kidsTalesLogo = document.getElementById('nav-container');
      // kidsTalesLogo.classList.add('shrink');
      console.log("id: " + id);
      //set the element that this runs on to have an active class if it has none, and it from all other profile-btn elements
      Array.from(document.getElementsByClassName('profile-btn')).forEach((element) => {
        element.classList.remove('active');
      });
      document.getElementById(id).classList.add('active');
      setActiveProfileId(id);
    } else {
      //DEMO MODE
      const kidsTalesLogo = document.getElementById('nav-container');
      kidsTalesLogo.classList.remove('small');
      console.log(id);
      const demoInstructions = document.getElementById('start-demo-instructions');
      demoInstructions.style.display = 'none';
      document.getElementById('demo-profile-btn').classList.add('active');
      setActiveProfileId(id);
      setLandingShelfOn(false);
      navigate('/story-gen-start'); // Navigate to the new route
    }
  }
  // ============================================ Page Functions ============================================



  // ============================================ Generating Pin Module ============================================
  const maxPinAttempts = 3;
  const [pinModuleOpen, setPinModuleOpen] = useState(false);
//   const [pinAttempts, setPinAttempts] = useState(maxPinAttempts);
  localStorage.setItem('pinAttemps', maxPinAttempts);
  const [isLocked, setIsLocked] = useState(false);

  function OpenPinModule(id) {
//   const OpenPinModule = (id) => {
    // if (!pinModuleOpen) {
      setPinModuleOpen(true);
      setSelectedProfileId(id);
      // setTimeout(() => {
      //   SetActiveProfile(id);
      // }, 500); // Adjust the delay as needed
    // }
  }

  function handlePinSubmit(authPin, setError) {
//   const handlePinSubmit = (authPin, setError) => {
    if (isLocked) {
      setError('Your pin was wrong too many times. Please try again later.');
      return;
    }

    console.log("handlePinSubmit: ", authPin);
    console.log("selectedProfileId: ", selectedProfileId);
    console.log("userId: ", userId);
    API.post('/api/auth/verify-gen', { userId, authPin })
      .then((response) => {
        // console.log("get authGen response: ", response);
        if (response.data.authGen !== undefined) {
          const authGen = response.data.authGen;
          console.log("authGen: ", authGen);

          if (authGen === true) {
            setPinModuleOpen(false); // Close popup
            SetActiveProfile(selectedProfileId); // Set active profile
            setLandingShelfOn(false); // Show landing shelf
            localStorage.setItem('pinAttemps', maxPinAttempts);
            navigate('/story-gen-start'); // Navigate to the new route
            // window.location.href = "/story-gen"; // Redirect to story-gen route
            // <Link to="/story-gen" />
            // setPinAttempts(maxPinAttempts);
          } else {
            // const _pinAttempts = pinAttempts - 1;
            const _pinAttempts = localStorage.getItem('pinAttemps') - 1;
            
            if (_pinAttempts <= 0) {
              setIsLocked(true);
              setError('Your pin was wrong too many times. Please try again later.'); // Show error message
              setTimeout(() => {
                setIsLocked(false);
                localStorage.setItem('pinAttemps', maxPinAttempts);
                // setPinAttempts(maxPinAttempts);
              }, 300000); // 5 minutes lockout
            } else {
              setError(`Are you sure you're allowed to generate stories? You have ${_pinAttempts} attempts left.`); // Show error message
              console.log("Are you sure you're allowed to generate stories?"); // Show error message
              localStorage.setItem('pinAttemps', _pinAttempts);
            //   setPinAttempts(_pinAttempts);
            }
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching profiles:', error);
      });
  };
  // ============================================ Generating Pin Module ============================================

  return (
    // <Router>
    <>
      {/* <GoogleOAuthProvider clientId={googleOauthClientId}> */}
        <div className="App">
          <Header />
          <div className='App-container' id='App-container'>
            {loggedIn && userId && ( 
              <div className="credit-counter" onClick={() => navigate('/pricing')}>
                {/* <span>💰 {credits}</span> */}
                <div class="credit-svg" >
                  {magicalBookSvg}
                </div>
                <span class="credit-value">{credits}</span>
              </div>
            )}
            {/* <div className='app-background' id='app-background'/> */}
            {pinModuleOpen && (
              <div className="pin-module">
                {/* <button onClick={() => setIsPopupOpen(true)}>Open PIN Popup</button> */}
                <PinModule
                  isOpen={pinModuleOpen}
                  onClose={() => setPinModuleOpen(false)}
                  onPinSubmit={handlePinSubmit}
                />
              </div>
            )}
            <div className='nav-container' id='nav-container'>
              {/* <Link className="logo-img" id="logo-img" to="/" onClick={() => showStartUI()}> */}
              <Link className="logo-img" id="logo-img" to="/" onClick={() => showStartUI()}>
                <img src={placeHolder_logo} alt="KidsTales_logo" />
              </Link>
              {loggedIn && userId ? ( 
                profiles.length > 0 ? (
                  <div className='profiles-container' id='profiles-container'>
                    {profiles.map((profile, index) => (
                        // <button className="profile-btn" id={profile.profile_id} key={index} onClick={() => SetActiveProfile(profile.profile_id)}>
                        <button className="profile-btn" 
                                id={profile.profile_id} key={index}
                                name={profile.profile_data.personal_information.first_name}
                                {...(profilesPageActive === false && {onClick: () => OpenPinModule(profile.profile_id)})}
                                // onClick={() => OpenPinModule(profile.profile_id)}
                        >
                        {profile.profile_image ? (
                          <img className='profile-img' src={`data:image/png;base64,${profile.profile_image}`}></img>
                        ) : (
                          <img className='profile-img' src={profile_placeholder}></img>
                        )}
                        {/* <img className='profile-img' src={`data:image/png;base64,${profile.profile_image}`}></img> */}
                        <div className='profile-name'>{profile.profile_data.personal_information.first_name}</div>
                        {/* <p>{profile.profile_id}</p> */}
                      </button>
                    ))}
                  </div>
                ) : (
                  <div className='profiles-container' id='demo-profiles-container'>
                    <div className='start-demo-instructions' id='start-demo-instructions'>
                      Welcome to KidsTales! It looks like you have no profiles yet. Create a profile to start creating personalized stories!
                    </div>
                    {/* <Link to="/create-profile" className="profile-btn" id="new-profile-btn"> */}
                    <Link to="/create-profile-ai" className="profile-btn" id="new-profile-btn">
                      <img className='profile-img' src={profile_placeholder}></img>
                      <div className='profile-name'>Create</div>
                    </Link>
                  </div>
                )
              ) : ( //Not logged in
                <div className='profiles-container' id='demo-profiles-container'>
                  <div className='start-demo-instructions' id='start-demo-instructions'>
                    Welcome to KidsTales! Start creating personalized children's books today, or try the demo!
                  </div>
                  <button className="profile-btn" id="demo-profile-btn" onClick={() => SetActiveProfile(demo_id)}>
                    <img className='profile-img' src={profile_placeholder}></img>
                    <div className='profile-name'>Demo</div>
                  </button>
                </div>
              )}
            </div>
            
            {/* { userId && landingShelfOn && ( */}
            { landingShelfOn && (
            // {landingShelfOn && location.pathname === '/' && (
              <div className='landing-shelf-container' id="landing-shelf-container" style={{ width: "100%", height: "100%" }}>
                <BookShelfPage thisLandingShelf={true}/>
              </div>
            )}

            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/story-gen-start" element={<StoryGenStartPage />} />

              {/* <Route path="/story-gen-summary" element={<StoryGenSummaryPage />} /> */}
              <Route
                path="/story-gen-summary"
                element={
                  <ProtectedRoute isAllowed={loggedIn || demoMode}>
                    <StoryGenSummaryPage />
                  </ProtectedRoute>
                }
              />
              {/* <Route path="/story-gen-book" element={<StoryGenBookPage />} /> */}
              <Route
                path="/story-gen-book"
                element={
                  <ProtectedRoute isAllowed={loggedIn || demoMode}>
                    <StoryGenBookPage />
                  </ProtectedRoute>
                }
              />
              {/* <Route path="/book-shelf" element={<BookShelfPage thisLandingShelf={false}/>} /> */}
              <Route
                path="/book-shelf"
                element={
                  <ProtectedRoute isAllowed={loggedIn || demoMode}>
                    <BookShelfPage thisLandingShelf={false} />
                  </ProtectedRoute>
                }
              />
              <Route path="/story-page" element={<StoryPage />} />
              <Route path="/story-page/:userId/:bookId/:pageNum" element={<StoryPage />} />
              <Route path="/story-book/:userId/:bookId" element={<StoryBookPage />} />
              <Route path="/profile-information" element={<ProfileInformationPage />} />
              <Route path="/create-profile" element={<CreateProfilePage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/checkout" element={<CheckoutPage />} />
              {/* <Route path="/cancel" element={<PricingPage />} />
              <Route path="/success" element={<AIProfileCreator />} /> */}
              <Route path="/create-profile-ai" element={<AIProfileCreator />} />
              <Route 
                path="/create-profile-ai" 
                element={
                  <ProtectedRoute isAllowed={loggedIn || demoMode}>
                    <AIProfileCreator />
                  </ProtectedRoute>
                }
              />

              <Route path="*" element={<Navigate to="/" />} />
            </Routes>

            {/* {(showPwaInstall && !appInstalled && !installPromptDismissed) && ( */}
            
            {(showPwaInstall && window.location.pathname === '/') && (
              <div className="install-app-container">
                <p className="install-app-instructions">
                  Install KidsTales to your device for a better experience!
                </p>
                <button className="install-app-btn" onClick={showInstallPrompt}>
                  Install App
                </button>
                <div className="install-dismiss-btn" onClick={dismissInstall}>
                  {dismissX}
                </div>
              </div>
            )}
            {(showAppleInstall && window.location.pathname === '/') && (
              <div className="install-app-container">
                <p className="install-app-instructions">
                  Install KidsTales to your device for a better experience! Tap the "share" icon, and then tap on "Add to home screen".
                </p>
                <div className="install-dismiss-btn" onClick={dismissInstall}>
                  {dismissX}
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      {/* </GoogleOAuthProvider> */}
    </>
    // </Router>
  );
}

export default App;